<template>
	<form action="">
		<div class="modal-card is-width-600">
			<header class="modal-card-head">
				<p class="modal-card-title">{{ titulo }} subcategoria</p>
				<button
					type="button"
					class="delete"
					@click="$emit('close')"/>
			</header>
			<section class="modal-card-body">
                <b-field label="Selecciona una categoria">
                    <b-select
                        placeholder="Categoria"
                        icon="tag-multiple"
                        v-model="categoriaSeleccionada"
                    >
                        <option
                        v-for="categoria in categorias"
                        :key="categoria.id"
                        :value="categoria.id"
                        >
                        {{ categoria.nombreCategoria }}
                        </option>
                    </b-select>
                </b-field>
				<b-field label="Nombre de la subcategoria" >
					<b-input  type="text" placeholder="Ej. Bocina" v-model="nombre"></b-input>
				</b-field>

				<b-field label="Abreviación subcategoria" >
					<b-input  type="text" placeholder="Ej. B" v-model="abrev"></b-input>
				</b-field>
			</section>
			<footer class="modal-card-foot">
				<b-button
					label="Cancelar"
					icon-left="cancel"
					size="is-medium"
					@click="$emit('close')" />
				<b-button
					label="Registrar"
					type="is-primary"
					icon-left="check"
					@click="registrar" />
			</footer>
		</div>
	</form>
</template>
<script>
    import HttpService from '@/Servicios/HttpService';

	export default{
		name: "DialogoSubCategorias",
		props: ['titulo', 'descripcion', 'idCategoria', 'abreviacion'],

		data:()=>({
			nombre: "",
			abrev: "",
            categorias: [],
            categoria: "",
            categoriaSeleccionada: null
		}),

		mounted(){
            this.obtenerCategorias()
            this.categoriaSeleccionada = this.idCategoria
			this.nombre = this.descripcion
			this.abrev = this.abreviacion
		},

		methods:{
			registrar(){
				if(!this.nombre || !this.categoriaSeleccionada){
					this.$buefy.toast.open({
                        type: 'is-danger',
                        message: 'Debes colocar la descripcion de la subcategoria o categoria.'
                    })
					return
				}

				this.$emit("registrar", this.nombre, this.categoriaSeleccionada, this.abrev)
			},
            obtenerCategorias() {
                this.cargando = true
                let payload = {
                    accion: 'obtener'
                }
                HttpService.obtenerConConsultas('categorias.php', payload)
                .then(categorias => {
                    this.categorias = categorias
                    // console.log(categorias)
                    this.cargando = false
                })
            }	
		}
	}
</script>