<template>
	<b-table
		:data="metodos"
		class="is-size-7">
			<b-table-column field="nombre" label="Nombre" v-slot="props">
				{{ props.row.descripcion }}
			</b-table-column>

			<b-table-column field="precio" label="Precio" v-slot="props">
				S/.{{ props.row.monto }}
			</b-table-column>

		</b-table>
</template>
<script>
	export default {
		name: "TablaMetodosPagados",
		props: ["metodos"]
	}
</script>