<template>
	<section>
		<b-button type="is-primary" size="is-medium" class="is-rounded-5" icon-left="plus" @click="agregarSubCategoria">
			Agregar subcategoría
		</b-button>
		<div class="is-align-end">
            <b-button type="is-success" icon-left="microsoft-excel" @click="exportar(subcategorias)">Exportar</b-button>
        </div>
		<b-select v-model="perPage">
            <option value="5">5 por página</option>
            <option value="10">10 por página</option>
            <option value="15">15 por página</option>
            <option value="20">20 por página</option>
        </b-select>
		<b-table
		:data="subcategorias"
		:paginated="isPaginated"
        :per-page="perPage"
		:current-page.sync="currentPage"
		>

			<b-table-column field="Categoria" label="Categoria" sortable searchable v-slot="props">
				{{ props.row.categoria }}
			</b-table-column>

			<b-table-column field="descripcion" label="Descripcion" sortable searchable v-slot="props">
				{{ props.row.descripcion }}
			</b-table-column>

			<b-table-column field="abreviacion" label="Abreviacion" sortable searchable v-slot="props">
				{{ props.row.abreviacion }}
			</b-table-column>

			<b-table-column field="eliminar" label="Eliminar" v-slot="props">
				<b-button type="is-danger" icon-left="delete" @click="eliminar(props.row)">Eliminar</b-button>
			</b-table-column>

			<b-table-column field="editar" label="Editar" v-slot="props">
				<b-button type="is-info" icon-left="pen" @click="editar(props.row)">Editar</b-button>
			</b-table-column>
		</b-table>
		<b-modal
			v-model="mostrarDialogoSubCategorias"
			has-modal-card
			trap-focus
			:destroy-on-hide="false"
			aria-role="dialog"
			aria-label="Modal"
			close-button-aria-label="Close"
			aria-modal>
			<dialogo-sub-categorias :titulo="tituloModal" :idCategoria="idCategoria" :descripcion="descripcion" :abreviacion="abreviacion" @close="onCerrarDialogo" @registrar="onRegistrar" v-if="mostrarDialogoSubCategorias"></dialogo-sub-categorias>
		</b-modal>

		<b-loading :is-full-page="true" v-model="cargando" :can-cancel="false"></b-loading>
	</section>
</template>
<script>
	import HttpService from '../../Servicios/HttpService'
	import DialogoSubCategorias from './DialogoSubCategorias'
	import Utiles from '../../Servicios/Utiles'
    import * as XLSX from 'xlsx'

	export default{
		name: "SubCategoriasComponent",
		components: { DialogoSubCategorias },
		data:()=>({     
			subcategorias: [],
			cargando: false,
			mostrarDialogoSubCategorias: false,
			tituloModal: "",
			descripcion: "",
            idSubcategoria: "",
			idCategoria: "",
			abreviacion:"",
			isPaginated: true,
			currentPage: 1,
            perPage: 5,
		}),

		mounted(){
			this.obtenerSubCategorias()
		},

		methods: {
			exportar(data) {
                if (data.length != 0) {
                    const excelData = data.map(row => {
                    return {
						categoria: row.categoria,
						abreviacion: row.abreviacion,
                        descripcion: row.descripcion
                        }  
                    })
                    const worksheet = XLSX.utils.json_to_sheet(excelData)
                    const workbook = XLSX.utils.book_new()
                    XLSX.utils.book_append_sheet(workbook, worksheet, 'Ventas')

                    // Exportar a Excel 
                    XLSX.writeFile(workbook, Utiles.generarfechahoraexportarexcel("SubCategorias"))
                } else {
                    alert("no hay nada que exportar");
                }
            },
			eliminar(subcategoria){
				this.$buefy.dialog.confirm({
					title: 'Eliminar subcategoría',
					message: 'Seguro que quieres <b>eliminar</b> la subcategoría ' + subcategoria.descripcion +'? Esta acción no se puede revertir.',
					confirmText: 'Sí, eliminar',
					cancelText: 'Cancelar',
					type: 'is-danger',
					hasIcon: true,
					onConfirm: () => {
						this.cargando = true
						HttpService.eliminar('subcategorias.php',{
							accion: 'eliminar',
							id: subcategoria.id
						})
						.then(resultado => {
							if(!resultado) {
								this.$buefy.toast.open('Error al eliminar')
								this.cargando = false
								return
							}

							if(resultado){
								this.cargando = false
								this.$buefy.toast.open({
									type: 'is-info',
									message: 'Subcategoría eliminada.'
								})
								this.obtenerSubCategorias()
							}
						})
					}
				})
			},

			editar(subcategoria){
				this.tituloModal = "Editar"
				this.mostrarDialogoSubCategorias = true
                this.idSubcategoria = subcategoria.id
				this.idCategoria = subcategoria.idcategoria
				this.descripcion = subcategoria.descripcion
				this.abreviacion = subcategoria.abreviacion
			}, 

			onCerrarDialogo(){
				this.mostrarDialogoSubCategorias = false
				this.descripcion = "" 
			},

			onRegistrar(descripcion, categoria, abreviacion) {
				this.cargando = true

				let accionARealizar = (this.tituloModal === 'Agregar') ? 'registrar' : (this.tituloModal === 'Editar') ? 'editar' : false

				HttpService.registrar('subcategorias.php', {
					accion: accionARealizar,
					subcategoria: {descripcion:descripcion, idcategoria: categoria, id: this.idSubcategoria, abreviacion: abreviacion}
				})
				.then(registrado => {
					if(registrado === 'existe'){
						this.$buefy.toast.open({
							type: 'is-primary',
							message: 'La sub ategoría' + descripcion +' ya existe, selecciona otra.'
						})
						this.cargando = false
						return 
					}

					if(registrado) {
						this.cargando = false
						this.$buefy.toast.open({
							type: 'is-info',
							message: 'Información de categoría registrada con éxito.'
						})
						this.obtenerSubCategorias()
						this.descripcion = ""
						this.mostrarDialogoSubCategorias = false
					}
				})
			},

			agregarSubCategoria(){
				this.tituloModal = "Agregar"
				this.mostrarDialogoSubCategorias = true
			},

			obtenerSubCategorias(){
				this.cargando = false
				let payload = {
					accion: 'obtener'
				}
				HttpService.obtenerConConsultas('subcategorias.php', payload)
				.then(subcategorias => {
					this.subcategorias = subcategorias
					this.cargando = false
				})
			}
		}
	}
</script>