<template>
  <b-navbar type="is-warning" class="fondo">
    <template #brand>
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <img src="@/assets/logo.png" />
      </b-navbar-item>
    </template>
    <template #start>
      <b-navbar-item tag="router-link" :to="{ path: '/' }" v-if="isShow">
        <b-icon icon="grid" size="is-medium"> </b-icon>
        <span class="is-margin-left-5">Inicio</span>
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ path: '/inventario' }">
        <b-icon icon="mdi mdi-package-variant-closed" size="is-medium"> </b-icon>
        <span class="is-margin-left-5">Inventario</span>
      </b-navbar-item>
      <b-navbar-item>
        <b-icon icon="fmdi mdi-point-of-sale" size="is-medium" class="is-pulled-left">
        </b-icon>
        <b-navbar-dropdown label="Vender" icon="home">
          <b-navbar-item tag="router-link" :to="{ path: '/realizar-venta' }">
            <b-icon icon="mdi mdi-package-variant-closed" size="is-medium"> </b-icon>
            <span class="is-margin-left-5">Productos</span>
          </b-navbar-item>
        </b-navbar-dropdown>
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ path: '/marcas-y-categorias' }">
        <b-icon icon="archive" size="is-medium"> </b-icon>
        <span class="is-margin-left-5">Marcas y categorías</span>
      </b-navbar-item>
      <b-navbar-item>
        <b-icon icon="file-chart-outline" size="is-medium" class="is-pulled-left">
        </b-icon>
        <b-navbar-dropdown label="Reportes" icon="home">
          <b-navbar-item tag="router-link" :to="{ path: '/reporte-ventas' }" >
            <b-icon icon="cash-register"> </b-icon>
            <span class="is-margin-left-5">Ventas</span>
          </b-navbar-item>
          <b-navbar-item tag="router-link" :to="{ path: '/reporte-servicios' }">
            <b-icon icon="mdi mdi-chart-timeline-variant"> </b-icon>
            <span class="is-margin-left-5">Servicios</span>
          </b-navbar-item>
          <b-navbar-item tag="router-link" :to="{ path: '/reporte-trabajadores' }">
            <b-icon icon="mdi mdi-toolbox-outline"> </b-icon>
            <span class="is-margin-left-5">Trabajadores</span>
          </b-navbar-item>
          <b-navbar-item tag="router-link" :to="{ path: '/reporte-pagos' }">
            <b-icon icon="cash"> </b-icon>
            <span class="is-margin-left-5">Pagos</span>
          </b-navbar-item>
        </b-navbar-dropdown>
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ path: '/usuarios' }" v-if="isShow">
        <b-icon icon="account" size="is-medium"> </b-icon>
        <span class="is-margin-left-5">Usuarios</span>
      </b-navbar-item>
      <b-navbar-item >
        <b-icon icon="mdi mdi-security" size="is-medium" class="is-pulled-left">
        </b-icon>
        <b-navbar-dropdown label="Administrar" icon="home">
          <b-navbar-item tag="router-link" :to="{ path: '/clientes' }">
            <b-icon icon="account-supervisor" size="is-medium"> </b-icon>
            <span class="is-margin-left-5">Clientes</span>
          </b-navbar-item>
          <b-navbar-item tag="router-link" :to="{ path: '/trabajadores' }" v-if="isShow">
            <b-icon icon="mdi mdi-account-clock" size="is-medium"> </b-icon>
            <span class="is-margin-left-5">Trabajadores</span>
          </b-navbar-item>
        </b-navbar-dropdown>
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ path: '/generar-codebar' }">
        <b-icon icon="mdi mdi-barcode" size="is-medium"> </b-icon>
        <span class="is-margin-left-5">Generar Código de Barra</span>
      </b-navbar-item>     
    </template>
    <template #end>
      <b-navbar-item tag="router-link" :to="{ path: '/configurar' }" v-if="isShow">
        <b-icon icon="cogs" size="is-medium"> </b-icon>
        <span class="is-margin-left-5">Configurar</span>
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ path: '/perfil' }">
        <b-icon icon="account" size="is-medium"> </b-icon>
        <span class="is-margin-left-5">{{ usuario }}</span>
      </b-navbar-item>
      <b-navbar-item>
        <div class="buttons">
          <a class="button is-light" @click="salir"> Salir </a>
        </div>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>
<script>
import AyudanteSesion from "../Servicios/AyudanteSesion";

export default {
  name: "EncabezadoComponent",

  data: () => ({
    usuario: "",
    isShow: true
  }),

  mounted() {
    this.usuario = AyudanteSesion.obtenerDatosSesion().usuario;
    if(localStorage.getItem('rol') == "Empleado") {
      this.isShow = false;
    }
  },

  methods: {
    salir() {
      AyudanteSesion.cerrarSesion();
    },
  },
};
</script>
