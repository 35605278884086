<template>
    <section class="box is-margin-top-5">
        <b-field label="Registro de Servicio Brindado" expanded>
            <busqueda-cliente @seleccionado="onSeleccionado"/>
        </b-field>
        <b-field grouped group-multiline>
            <b-field label="Servicio" expanded >
                <b-input step="any" icon="mdi mdi-account-wrench" type="text" v-model="descripcion" placeholder="Ingrese nombre del servicio"></b-input>
            </b-field>
            <b-field label="Precio" expanded>
                <b-input step="any" icon="mdi mdi-cash" type="number" v-model="precio" placeholder="Ingrese Precio"></b-input>
            </b-field>
            <b-field label="Selecciona un trabajador" expanded>
                <b-select
                expanded
                placeholder="Trabajador"
                icon="mdi mdi-account-hard-hat"
                v-model="trabajador"
                @input="onSeleccionadoTrabajador"
                >
                <option
                    v-for="trabajador in trabajadores"
                    :key="trabajador.id"
                    :value="trabajador.id"
                >
                    {{ trabajador.nombrecompleto }}
                </option>
                </b-select>
            </b-field>
        </b-field>
        <br />
         <div class="buttons has-text-centered">
            <b-button type="is-primary" size="is-large" icon-left="check" @click="registrar">Registrar</b-button>
            <b-button type="is-dark" size="is-large" icon-left="cancel" tag="router-link" to="/">Cancelar</b-button>
        </div>
        
    </section>
</template>
<script>
    import HttpService from "../../Servicios/HttpService";
    import BusquedaCliente from "../Clientes/BusquedaCliente";

    export default {
		name: "RealizarServicio",
        components: { BusquedaCliente },
		data:()=>({
			trabajadores: [],
            cliente: {},
            servicio: null,
            descripcion: "",
            precio: 0,
            trabajador: null,
		}),
        
        mounted() {
            this.obtenerTrabajadores();
        },

		methods: {
            onSeleccionado(cliente) {
                this.cliente = cliente;
            },
            onSeleccionadoTrabajador(trabajador) {
                this.trabajador = trabajador
            },
            registrar(){
                this.servicio = {
                    descripcion: this.descripcion,
                    trabajador: this.trabajador,
                    precio: this.precio,
                    cliente: this.cliente
                };

                this.cargando = true;
                let datos = {
                    accion: "vender",
                    datos: this.servicio,
                };

                HttpService.registrar("venderservicios.php", datos).then((registrado) => {
                    if (registrado) {
                        this.cargando = false;
                        this.$buefy.toast.open({
                            type: "is-info",
                            message:  "Registrado con éxito",
                        });
                    }
                });
			},
			obtenerTrabajadores() {
                let payload = {
                    accion: "obtener",
                };
                HttpService.obtenerConConsultas("trabajadores.php", payload).then(
                    (trabajadores) => {
                        this.trabajadores = trabajadores;
                    }
                );
            },
		}
	}
</script>