<template>
  <form action="" class="is-flex">
    <b-field>
      <b-select
        placeholder="Metodo de Pago"
        icon="tag-multiple"
        v-model="metodoSeleccionado"
        @input="seleccionarMetodo(index)"
      >
        <option
          v-for="metodo in metodos"
          :key="metodo.id"
          :value="metodo.id"
        >
          {{ metodo.descripcion }}
        </option>
      </b-select>
    </b-field>
    <b-field>
      <b-input
        v-model="montoMetodoSeleccionado"
        type="text"
        placeholder="Monto"
        @input="seleccionarMetodo(index)"
      ></b-input>
    </b-field>
    <b-button type="is-danger" @click="eliminar(index)">
        <b-icon icon="delete" />
    </b-button>
  </form>
</template>

<script>
import HttpService from '@/Servicios/HttpService';

export default {
  name: "DialogoMetodoPago",
  props: ["index"],
  data: ()=>({
    cargando: false,
    metodos: [],
    metodo: "",
    metodoSeleccionado: null,
    montoMetodoSeleccionado: 0
  }),
  mounted() {
      this.obtenerMetodosPago()
  },
  methods: {
    seleccionarMetodo(index) {
      this.$emit('metodopagogenerado', {index: index, idmetodo: this.metodoSeleccionado, monto: this.montoMetodoSeleccionado})
    },
    eliminar(index) {
      this.$emit('eliminarmetodopago', index)
    },
    obtenerMetodosPago() {
      this.cargando = true
      let payload = {
        accion: 'obtener'
      }
      HttpService.obtenerConConsultas('metodospago.php', payload)
      .then(metodospago => {
        this.metodos = metodospago
        this.cargando = false
      })
    }
  }
};
</script>
