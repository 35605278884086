<template>
    <section>
        <p class="title is-1">Reporte de pagos</p> 
         <b-breadcrumb
            align="is-left"
        >
            <b-breadcrumb-item tag='router-link' to="/">Inicio</b-breadcrumb-item>
            <b-breadcrumb-item active>Pagos</b-breadcrumb-item>
        </b-breadcrumb>
        <busqueda-en-fecha @seleccionada="onBusquedaEnFecha"/>
        <mensaje-inicial class="mt-2" :titulo="'No se han encontrado registros :('" :subtitulo="'Aquí aparecerán los reportes por pagos'" v-if="ventas_pagos.length<1"/>
        <div class="mt-2" v-if="ventas_pagos.length>0">
            <div class="is-align-end">
                <b-button type="is-success" icon-left="microsoft-excel" @click="exportar(ventas_pagos)">Exportar</b-button>
            </div>
            <b-select v-model="perPage">
                <option value="5">5 por página</option>
                <option value="10">10 por página</option>
                <option value="15">15 por página</option>
                <option value="20">20 por página</option>
            </b-select>

            <b-table
            class="box"
            :data="ventas_pagos"
            :paginated="isPaginated"
            :per-page="perPage"
            :current-page.sync="currentPage"
            :pagination-simple="isPaginationSimple"
            :pagination-position="paginationPosition"
            :default-sort-direction="defaultSortDirection"
            :pagination-rounded="isPaginationRounded"
            :sort-icon="sortIcon"
            :sort-icon-size="sortIconSize"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
            >
                <b-table-column field="metodopago" label="Metodo Pago" sortable searchable v-slot="props">
                    {{ props.row.metodopago }}
                </b-table-column>

                <b-table-column field="total" label="Total" sortable searchable v-slot="props">
                    {{ props.row.total }}
                </b-table-column>

            </b-table>
        </div>
    </section>
</template>
<script>

    import BusquedaEnFecha from '../Extras/BusquedaEnFecha'
    import HttpService from '../../Servicios/HttpService'
    import MensajeInicial from '../Extras/MensajeInicial'
    import Utiles from '../../Servicios/Utiles'
    import * as XLSX from 'xlsx'

    export default {
        name: "ReportePagos",
        components: { BusquedaEnFecha, MensajeInicial },
        data:()=>({
            filtros: {
                fechaInicio: "",
                fechaFin: ""
            },
            cargando: false,
            ventas_pagos: [],
            isPaginated: true,
            isPaginationSimple: false,
            isPaginationRounded: true,
            paginationPosition: 'bottom',
            defaultSortDirection: 'asc',
            sortIcon: 'arrow-up',
            sortIconSize: 'is-medium',
            currentPage: 1,
            perPage: 5,
        }),

        mounted(){
            this.obtenerVentasPago()
        },

        methods: {
            exportar(data) {
                if (data.length != 0) {
                    const excelData = data.map(row => {
                    return {
                        MetodoPago: row.metodopago,
                        Total: row.total,
                        }  
                    })
                    const worksheet = XLSX.utils.json_to_sheet(excelData)
                    const workbook = XLSX.utils.book_new()
                    XLSX.utils.book_append_sheet(workbook, worksheet, 'Pagos')

                    // Exportar a Excel 
                    XLSX.writeFile(workbook, Utiles.generarfechahoraexportarexcel("Pagos"))
                } else {
                    alert("no hay nada que exportar");
                }
            },
            onBusquedaEnFecha(fechas){
                this.filtros.fechaInicio = fechas[0].toISOString().split('T')[0]
                this.filtros.fechaFin = fechas[1].toISOString().split('T')[0]
                this.obtenerVentasPago()
            },
            obtenerVentasPago(){
                this.cargando = true
                let payload = {
                    filtros: this.filtros,
                    accion: 'obtener_ventas_pagos'
                }
                HttpService.obtenerConConsultas('vender.php', payload)
                .then(resultado => {
                    this.ventas_pagos = resultado.ventas
                    this.cargando = false
                })
            }
        }
    }
</script>