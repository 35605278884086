<template>
  <div id="print-area">
    <br>
    <div class="is-flex is-flex-direction-column" id="select-generate">
      <div>
        <b-field>
          <b-switch v-model="individual" type="is-info">
            ¿Individual?
          </b-switch>
        </b-field>
      </div>
      <br>
      <div v-if="individual">
        <buscar-producto @seleccionado="onSeleccionado" />

        <b-field grouped group-multiline>
          <b-field label="Cantidad" expanded>
            <b-numberinput v-model="individualQty"></b-numberinput>
          </b-field>
  
          <b-field label="Dar click">
            <b-button @click="print">Imprimir</b-button>
          </b-field>
        </b-field>
      </div>
      <div v-else>
        <b-field label="Selecciona una subcategoria" class="is-margin-r-5">
          <b-select
            placeholder="Subcategoria"
            icon="tag-multiple"
            v-model="subcategoriaSeleccionada"
          >
            <option
              v-for="subcategoria in subcategorias"
              :key="subcategoria.id"
              :value="subcategoria.id"
            >
              {{ subcategoria.descripcion }}
            </option>
          </b-select>
        </b-field>
        <b-field label="" class="is-text-white-label">
          <b-button
            type="is-primary"
            size="is-short"
            icon-left="reload"
            @click="generar"
            >Generar</b-button
          >
          <b-button @click="print">Imprimir</b-button>
        </b-field>
      </div>
    </div>
    <br>
    <div v-if="individual">
      <div v-if="selectedProduct && selectedProduct.codigoBarra">
        <div class="is-flex is-flex-wrap-wrap is-align-content-space-between is-gap-5 is-text-align-center">
          <div v-for="n in individualQty" :key="n" class="is-border-dotted is-width-auto is-padding-x">
            <vue-barcode :value="selectedProduct.codigoBarra" format="CODE128A"  width="3" height="40" ean="true"></vue-barcode>
            {{ selectedProduct.nombre }}
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="is-flex is-flex-wrap-wrap is-align-content-space-between is-gap-5 is-text-align-center">
      <div v-for="product in productos" :key="product.codigoproducto" class="is-border-dotted is-width-auto is-padding-x">
        <vue-barcode
          :value="product.codigoproducto" format="CODE128A" width="3" height="40" ean="true"
        >
        </vue-barcode>
        <p>{{ product.nombreproducto }}</p>
      </div>
    </div>
    </div>
    <br>
  </div>
</template>

<script>
import VueBarcode from "vue-barcode";
import BuscarProducto from "../Inventario/BuscarProducto.vue";
import HttpService from "@/Servicios/HttpService";
export default {
  components: {
    VueBarcode,
    BuscarProducto
  },
  data: () => ({
    productos: [],
    subcategorias: [],
    subcategoria: "",
    subcategoriaSeleccionada: null,
    individual: false,
    individualQty: 1,
    selectedProduct: {},
  }),
  mounted() {
    this.obtenerSubCategorias();
  },
  watch: {
    individual(newValue) {
      console.log(newValue)
      if (newValue) {
        // Modo individual
        this.resetMassive();
      } else {        
        // Modo masivo        
        this.resetIndividual();
      }
    }
  },
  methods: {
    resetIndividual() {
      this.selectedProduct = {};
      this.individualQty = 1;
    },
    resetMassive() {
      this.productos = [];
    },
    onSeleccionado(producto) {
      this.selectedProduct = producto;
    },
    generar() {
      let payload = {
        accion: "obtener_por_subcategoria",
        idsubcategoria: this.subcategoriaSeleccionada
      };
      HttpService.obtenerConConsultas("productos.php", payload).then(
        (productos) => {
          this.productos = productos;
        }
      );
    },
    obtenerSubCategorias() {
      let payload = {
        accion: "obtener",
      };
      HttpService.obtenerConConsultas("subcategorias.php", payload).then(
        (subcategorias) => {
          this.subcategorias = subcategorias;
        }
      );
    },
    print() {
      try {
        // Crea una nueva ventana con una vista específica para imprimir
        const printWindow = window.open('', '_blank');
        const printContents = document.getElementById('print-area').innerHTML;

        // Construye el contenido a imprimir en la nueva ventana
        const printHTML = `
          <!DOCTYPE html>
          <html lang="es">
          <head>
            <title>Imprimir</title>
            <style>
              #select-generate {
                display: none !important;
              }
              .is-flex {
                display: flex !important;
              } 
              .is-flex-wrap-wrap {
                flex-wrap: wrap !important;
              } 
              .is-align-content-space-between {
                align-content: space-between !important;
              } 
              .is-gap-5 {
                gap: 0.2rem;
              }
              .is-text-align-center {
                text-align: center;
              }
              .is-border-dotted {
                border-width: 2px;
                border-style: dotted;
              }
              .is-width-150 {
                width: 250px;
              }
              p {
                margin: 0;
                padding: 0;
              }
            </style>
          </head>
          <body>
            ${printContents}
          </body>
          </html>
        `;

        // Escribe el contenido en la nueva ventana
        printWindow.document.open();
        printWindow.document.write(printHTML);
        printWindow.document.close();

        // Espera a que se cargue la ventana antes de imprimir
        printWindow.onload = () => {
          printWindow.print();
          printWindow.onafterprint = () => {
            // Cierra la ventana después de imprimir
            printWindow.close();
          };
        };
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
