<template>
	<section class="box">
		<b-field grouped group-multiline>
			<b-field label="Apellido Paterno" expanded>
				<b-input step="any" icon="account" type="text" placeholder="Ej. Don Paco" v-model="datosCliente.apellidoPaterno"></b-input>
			</b-field>
			<b-field label="Apellido Materno" expanded>
				<b-input step="any" icon="account" type="text" placeholder="Ej. Don Paco" v-model="datosCliente.apellidoMaterno"></b-input>
			</b-field>
			<b-field label="Nombres" expanded>
				<b-input step="any" icon="account" type="text" placeholder="Ej. Don Paco" v-model="datosCliente.nombres"></b-input>
			</b-field>
		</b-field>
		<br>
		<b-field grouped group-multiline>
			<b-field label="Teléfono del cliente" expanded>
				<b-input step="any" icon="phone" type="number" placeholder="Ej. 2311459874" v-model="datosCliente.telefono"></b-input>
			</b-field>
		</b-field>
		<br />
         <div class="buttons has-text-centered">
            <b-button type="is-primary" size="is-large" icon-left="check" @click="registrar">Registrar</b-button>
            <b-button type="is-dark" size="is-large" icon-left="cancel" tag="router-link" to="/clientes">Cancelar</b-button>
        </div>
        <errores-component :errores="mensajesError" v-if="mensajesError.length > 0" />
	</section>
</template>
<script>
	import Utiles from '../../Servicios/Utiles'
	import ErroresComponent from '../Extras/ErroresComponent'
	export default {
		name: "FormCliente",
		props: ["cliente"],
		components: { ErroresComponent },

		data:()=>({
			datosCliente: {
				apellidoPaterno: "",
				apellidoMaterno: "",
				nombres: "",
				telefono: ""
			},
			mensajesError: []
		}),

		mounted(){
			this.datosCliente = this.cliente
		},

		methods: {
			registrar(){
				console.log(this.datosCliente)
				this.mensajesError = Utiles.validarDatos(this.datosCliente)
				if(this.mensajesError.length > 0) return
				this.$emit("registrar", this.datosCliente)
				this.datosCliente  = {
					apellidoPaterno: "",
					apellidoMaterno: "",
					nombres: "",
					telefono: ""
				}
			}
		}
	}
</script>