<template>
  <section>
    <buscar-producto @seleccionado="onSeleccionado" />
    <mensaje-inicial
      :titulo="'No has agregado productos'"
      :subtitulo="'Agrega algunos productos a la lista para venderlos :)'"
      v-if="productos.length < 1"
    />
    <div v-if="productos.length > 0">
      <tabla-productos
        :listaProductos="productos"
        @quitar="onQuitar"
        @aumentar="onAumentar"
        @actualizarTotal="onActualizarTotal"
      />

      <div>
        <b-button @click="onAgregarMetodo">Añadir Método de Pago</b-button>
      </div>

      <b-field label="Seleccione monto y metodo de pago">
        <div v-if="metodosPago.length > 0">
          <div v-for="(metodoPago, index) in metodosPago" :key="index">
            <dialogo-metodo-pago :index="index" @metodopagogenerado="onAgregarMetodoPago" @eliminarmetodopago="onEliminarMetodoPago"/>
          </div>
        </div>
      </b-field> 
      <errores-component
      :errores="mensajesError"
      v-if="mensajesError.length > 0"
    />
      <div class="notification is-primary mt-3">
        <p
          class="has-text-weight-bold has-text-centered is-font-size-5"
        >
          Total S/.{{ total }}
        </p>
        <nav class="level mt-2">
          <div class="level-item has-text-centered">
            <b-button
              class="button is-responsive"
              type="is-success"
              inverted
              icon-left="check"
              size="is-large"
              @click="abrirDialogo('venta')"
            >
              Terminar venta
            </b-button>
          </div>
          <div class="level-item has-text-centered">
            <b-button
              class="button is-responsive"
              type="is-danger"
              inverted
              icon-left="cancel"
              size="is-large"
              @click="cancelarVenta"
            >
              Cancelar
            </b-button>
          </div>
        </nav>
      </div>
    </div>
    <b-loading
      :is-full-page="true"
      v-model="cargando"
      :can-cancel="false"
    ></b-loading>
    <b-modal
      v-model="mostrarDialogo"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Modal Terminar Venta"
      close-button-aria-label="Close"
      aria-modal
    >
      <dialogo-terminar-venta
        :totalVenta="total"
        @close="onCerrar"
        @terminar="onTerminar"
        v-if="mostrarTerminarVenta"
      ></dialogo-terminar-venta>
      <dialogo-agregar-cuenta
        :totalVenta="total"
        @close="onCerrar"
        @terminar="onTerminar"
        v-if="mostrarAgregarCuenta"
      ></dialogo-agregar-cuenta>
      <dialogo-agregar-apartado
        :totalVenta="total"
        @close="onCerrar"
        @terminar="onTerminar"
        v-if="mostrarAgregarApartado"
      ></dialogo-agregar-apartado>
      <dialogo-cotizar
        :totalVenta="total"
        @close="onCerrar"
        @terminar="onTerminar"
        v-if="mostrarRegistrarCotizacion"
      ></dialogo-cotizar>
    </b-modal>
    <comprobante-compra
      :venta="this.ventaRealizada"
      :tipo="tipoVenta"
      @impreso="onImpreso"
      v-if="mostrarComprobante"
    />
  </section>
</template>
<script>
import BuscarProducto from "../Inventario/BuscarProducto.vue";
import TablaProductos from "./TablaProductos.vue";
import DialogoTerminarVenta from "./DialogoTerminarVenta";
import DialogoAgregarCuenta from "./DialogoAgregarCuenta";
import DialogoAgregarApartado from "./DialogoAgregarApartado";
import DialogoCotizar from "./DialogoCotizar";
import DialogoMetodoPago from "./DialogoMetodoPago";
import ComprobanteCompra from "./ComprobanteCompra";
import MensajeInicial from "../Extras/MensajeInicial";
import AyudanteSesion from "../../Servicios/AyudanteSesion";
import HttpService from "../../Servicios/HttpService";
import ErroresComponent from "../Extras/ErroresComponent";
import Utiles from "../../Servicios/Utiles";

export default {
  name: "RealizarVenta",
  components: {
    BuscarProducto,
    TablaProductos,
    DialogoTerminarVenta,
    DialogoAgregarCuenta,
    DialogoAgregarApartado,
    DialogoCotizar,
    DialogoMetodoPago,
    MensajeInicial,
    ComprobanteCompra,
    ErroresComponent
  },

  data: () => ({
    metodosPago: [],
    cargando: false,
    productos: [],
    total: 0,
    mostrarDialogo: false,
    mostrarTerminarVenta: false,
    mostrarAgregarCuenta: false,
    mostrarAgregarApartado: false,
    mostrarRegistrarCotizacion: false,
    mostrarMetodoPago: false,
    ventaRealizada: null,
    mostrarComprobante: false,
    tipoVenta: "",
    mensajesError: [],
  }),

  methods: {
    onAgregarMetodoPago(metodo) {
      //index | idmetodo | monto
      const index = this.metodosPago.findIndex(x => x.index === metodo.index)
      this.metodosPago[index].monto = metodo.monto
      this.metodosPago[index].idmetodo = metodo.idmetodo
    },
    onAgregarMetodo() {
      var cantidad = this.metodosPago.length
      this.metodosPago.push({
        index: cantidad, idmetodo: null,  monto:0
      })
    },
    onImpreso(resultado) {
      this.mostrarComprobante = resultado;
    },

    onTerminar(venta) {
      this.ventaRealizada = {
        total: this.total,
        productos: this.productos,
        cliente: venta.cliente.id,
        usuario: AyudanteSesion.obtenerDatosSesion().id,
        nombreCliente: venta.cliente.nombreCompleto,
        nombreCompleto: venta.cliente.nombreCompleto,
        nombreUsuario: AyudanteSesion.obtenerDatosSesion().usuario,
        fecha: new Date().toJSON().slice(0, 10).replace(/-/g, "/"),
        trabajador: venta.trabajador,
        nombreTrabajador: venta.trabajador.nombreTrabajador
      };

      let tipo = venta.tipo;

      console.log(venta)

      switch (tipo) {
        case "venta":
          this.ventaRealizada.tipo = "venta";
          this.ventaRealizada.metodospago = this.metodosPago;
          this.ventaRealizada.totalsindescuento = this.calcularTotalSinDescuento();
          this.ventaRealizada.cambio = venta.cambio;
          break;
        case "cuenta":
          this.ventaRealizada.tipo = "cuenta";
          this.ventaRealizada.pagado = venta.pagado;
          this.ventaRealizada.porPagar = venta.porPagar;
          break;
        case "apartado":
          this.ventaRealizada.tipo = "apartado";
          this.ventaRealizada.pagado = venta.pagado;
          this.ventaRealizada.porPagar = venta.porPagar;
          break;
        case "cotiza":
          this.ventaRealizada.tipo = "cotiza";
          break;
      }

      this.tipoVenta = venta.tipo;

      this.cargando = true;
      let datos = {
        accion: "vender",
        datos: this.ventaRealizada,
      };

      HttpService.registrar("vender.php", datos).then((registrado) => {
        if (registrado) {
          this.productos = [];
          this.total = 0;
          this.metodosPago = [];
          this.cargando = false;
          this.mostrarTerminarVenta =
            this.mostrarAgregarCuenta =
            this.mostrarAgregarApartado =
            this.mostrarRegistrarCotizacion =
              false;
          this.mostrarDialogo = false;
          this.$buefy.toast.open({
            type: "is-info",
            message: tipo.toUpperCase() + " registrado con éxito",
          });
          this.mostrarComprobante = true;
        }
      });
    },

    cancelarVenta() {
      this.$buefy.dialog.confirm({
        title: "Cancelar venta",
        message: "¿Seguro que deseas cancelar la venta?",
        confirmText: "Sí, cancelar",
        cancelText: "No, continuar",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          this.productos = [];
          this.total = 0;
          this.$buefy.toast.open("Venta cancelada");
        },
      });
    },

    abrirDialogo(opcion) {
      var sumaMontos = this.metodosPago.reduce((acumulador, metodo) => acumulador + parseFloat(metodo.monto), 0);
      this.mensajesError = Utiles.validarTotalVenta(sumaMontos ,this.total);
      if (this.mensajesError.length > 0) return;
      this.mostrarDialogo = true;
      switch (opcion) {
        case "venta":
          this.mostrarTerminarVenta = true;
          this.mostrarAgregarCuenta =
            this.mostrarAgregarApartado =
            this.mostrarRegistrarCotizacion =
              false;
          break;
        case "cuenta":
          this.mostrarAgregarCuenta = true;
          this.mostrarTerminarVenta =
            this.mostrarAgregarApartado =
            this.mostrarRegistrarCotizacion =
              false;
          break;
        case "apartado":
          this.mostrarAgregarApartado = true;
          this.mostrarAgregarCuenta =
            this.mostrarTerminarVenta =
            this.mostrarRegistrarCotizacion =
              false;
          break;
        case "cotiza":
          this.mostrarRegistrarCotizacion = true;
          this.mostrarAgregarCuenta =
            this.mostrarTerminarVenta =
            this.mostrarAgregarApartado =
              false;
          break;
        case "metodopago":
          this.mostrarMetodoPago = true;
          this.mostrarRegistrarCotizacion =
            this.mostrarAgregarCuenta =
            this.mostrarTerminarVenta =
            this.mostrarAgregarApartado =
              false;
      }
    },

    onCerrar(opcion) {
      this.mostrarDialogo = false;
      if (
        opcion === "venta" ||
        opcion === "cuenta" ||
        opcion === "apartado" ||
        opcion === "cotiza"
      )
        this.mostrarTerminarVenta =
          this.mostrarAgregarCuenta =
          this.mostrarAgregarApartado =
          this.mostrarRegistrarCotizacion =
            false;
    },

    onQuitar(id) {
      let indice = this.productos.findIndex((producto) => producto.id === id);
      this.productos.splice(indice, 1);
    },
    onEliminarMetodoPago(index) {
      let indice = this.metodosPago.findIndex(x => x.index === index);
      this.metodosPago.splice(indice, 1);
    },

    onActualizarTotal() {
      this.total = this.calcularTotal()
    },

    onAumentar(producto) {
      let verificaExistencia = this.verificarExistenciaAlcanzada(
        producto.existencia,
        producto.id
      );

      if (verificaExistencia) return;

      if (producto.vendidoMayoreo) {
        this.verificarMayoreo(
          producto.cantidadMayoreo,
          producto.id,
          producto.precioMayoreo
        );
      }
      this.total = this.calcularTotal();
    },

    onSeleccionado(producto) {
      let verificaExistencia = this.verificarExistenciaAlcanzada(
        producto.existencia,
        producto.id
      );

      if (verificaExistencia) return;

      if (producto.vendidoMayoreo) {
        this.verificarMayoreo(
          producto.cantidadMayoreo,
          producto.id,
          producto.precioMayoreo
        );
      }

      let existeEnLista = this.verificarSiEstaEnLista(producto.id);

      if (existeEnLista >= 0) {
        this.aumentarCantidad(existeEnLista);
        this.total = this.calcularTotal();
        return;
      }
      
      this.agregarALista(producto);
      this.total = this.calcularTotal();
    },

    agregarALista(producto) {
      this.productos.push({
        id: producto.id,
        codigo: producto.codigo,
        codigobarra: producto.codigoBarra,
        nombremarca: producto.NombreMarca,
        nombre: producto.nombre,
        precio: producto.precioVenta,
        marca: producto.marca,
        cantidad: 1,
        descuento: 0,
        precioCompra: producto.precioCompra,
        existencia: producto.existencia,
        vendidoMayoreo: producto.vendidoMayoreo,
        cantidadMayoreo: producto.cantidadMayoreo,
        precioMayoreo: producto.precioMayoreo,
        mayoreoAplicado: false,
      });
    },

    verificarExistenciaAlcanzada(existencia, id) {
      let resultado = false;
      this.productos.forEach((producto) => {
        if (producto.id === id) {
          if (parseInt(producto.cantidad) >= parseInt(existencia)) {
            this.$buefy.toast.open({
              type: "is-danger",
              message:
                "El producto " +
                producto.nombre +
                "ha alcanzado la existencia máxima. Solo tienes " +
                producto.existencia,
            });
            producto.cantidad = existencia;
            resultado = true;
          }
        }
      });
      return resultado;
    },

    verificarMayoreo(cantidadMayoreo, id, precioMayoreo) {
      this.productos.forEach((producto) => {
        if (producto.mayoreoAplicado) return;
        if (producto.id === id) {
          if (producto.cantidad >= parseInt(cantidadMayoreo)) {
            this.$buefy.dialog.confirm({
              confirmText: "Sí, aplicar",
              cancelText: "No aplicar",
              message:
                "El producto " +
                producto.nombre +
                " tiene mayoreo a partir de " +
                cantidadMayoreo +
                " piezas, ¿Desea aplicar el mayoreo?",
              onConfirm: () => {
                producto.precio = precioMayoreo;
                producto.mayoreoAplicado = true;
                this.$buefy.toast.open(
                  "Mayoreo aplicado correctamente a " + producto.nombre
                );
              },
            });
          }
        }
      });
    },

    verificarSiEstaEnLista(id) {
      return this.productos.findIndex((producto) => producto.id === id);
    },

    aumentarCantidad(indice) {
      let lista = this.productos;
      let producto = lista[indice];
      producto.cantidad++;
      // producto.existencia--;
      this.productos = lista;
    },

    calcularTotal() {
      let total = 0;
      this.productos.forEach((producto) => {
        total =
          (parseFloat(producto.cantidad * producto.precio) + parseFloat(total) - producto.descuento);
      });
      return total;
    },
    calcularTotalSinDescuento() {
      let total = 0;
      this.productos.forEach((producto) => {
        total =
          (parseFloat(producto.cantidad * producto.precio) + parseFloat(total));
      });
      return total;
    },
  },
};
</script>
