<template>
	<section>
		<nav-component :titulo="'Trabajadores'" :link="{ path: '/agregar-trabajador' }" :texto="'Agregar trabajador'" />
		<b-select v-model="perPage">
            <option value="5">5 por página</option>
            <option value="10">10 por página</option>
            <option value="15">15 por página</option>
            <option value="20">20 por página</option>
        </b-select>
		<b-table
		:data="trabajadores"
		:paginated="isPaginated"
        :per-page="perPage"
		:current-page.sync="currentPage"
		>

            <b-table-column field="apellidos" label="Apellidos" sortable searchable v-slot="props">
				{{ props.row.apellidoPaterno }} {{ props.row.apellidoMaterno }}
			</b-table-column>

			<b-table-column field="nombres" label="Nombres" sortable searchable v-slot="props">
				{{ props.row.nombre }}
			</b-table-column>

			<b-table-column field="fechaingreso" label="Fecha Ingreso" sortable searchable v-slot="props">
				{{ props.row.fechaIngreso }}
			</b-table-column>

			<b-table-column field="eliminar" label="Eliminar" v-slot="props">
				<b-button type="is-danger" icon-left="delete" @click="eliminar(props.row.id)">Eliminar</b-button>
			</b-table-column>

			<b-table-column field="editar" label="Editar" v-slot="props">
				<b-button type="is-info" icon-left="pen" @click="editar(props.row.id)">Editar</b-button>
			</b-table-column>
		</b-table>
		<b-loading :is-full-page="true" v-model="cargando" :can-cancel="false"></b-loading>
	</section>
</template>
<script>
	import NavComponent from '../Extras/NavComponent'
	import HttpService from '../../Servicios/HttpService'

	export default{
		name: "TrabajadorComponent",
		components: { NavComponent },

		data:()=>({
			cargando: false,
			trabajadores: [],
			isPaginated: true,
			currentPage: 1,
            perPage: 5,
		}),

		mounted(){
			this.obtenerTrabajadors()
		},

		methods:{
			async eliminar(idTrabajador){
                this.$buefy.dialog.confirm({
                    title: 'Eliminar trabajador',
                    message: 'Seguro que quieres <b>eliminar</b> este trabajador? Esta acción no se puede revertir.',
                    confirmText: 'Sí, eliminar',
                    cancelText: 'Cancelar',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {
                        this.cargando = true
                        HttpService.eliminar('trabajadores.php',{
                            accion: 'eliminar',
                            id: idTrabajador
                        })
                        .then(resultado => {
                            if(!resultado) {
                                this.$buefy.toast.open('Error al eliminar')
                                this.cargando = false
                                return
                            }

                            if(resultado){
                                this.cargando = false
                                this.$buefy.toast.open({
                                    type: 'is-info',
                                    message: 'Trabajador eliminado.'
                                })
                                this.obtenerTrabajadors()
                            }
                        })
                    }
                })
            },

			editar(idTrabajador){
				this.$router.push({
					name: "EditarTrabajador",
					params: { id: idTrabajador}
				})
			},

			obtenerTrabajadors(){
				this.cargando = true
				let payload = {
					accion: "obtener",
				}

				HttpService.obtenerConConsultas("trabajadores.php", payload)
				.then(trabajadores =>{
					this.trabajadores = trabajadores
					this.cargando = false
				})
			}
		}
	}
</script>