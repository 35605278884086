<template>
	<b-table
		:data="productos"
		class="is-size-7">
		<b-table-column field="nombre" label="Nombre" v-slot="props">
				{{ props.row.nombre }}
			</b-table-column>

			<b-table-column field="marca" label="Marca" v-slot="props">
				{{ props.row.marca }}
			</b-table-column>

			<b-table-column field="precio" label="Precio" v-slot="props">
				S/.{{ props.row.precio }}
			</b-table-column>

			<b-table-column field="cantidad" label="Cantidad" v-slot="props">
				{{ props.row.cantidad}}
			</b-table-column>

			<b-table-column field="subtotal" label="Subtotal" v-slot="props">
				<b>S/.{{ (props.row.precio * props.row.cantidad) - props.row.descuento }}</b>
			</b-table-column>

			<b-table-column field="descuento" label="Descuento" v-slot="props">
				<b>S/.{{ props.row.descuento }}</b>
			</b-table-column>
		</b-table>
</template>
<script>
	export default {
		name: "TablaProductosVendidos",
		props: ["productos"]
	}
</script>