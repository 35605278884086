<template>
    <section>
        <h1 class="title is-1">Editar trabajador</h1>
        <b-breadcrumb
            align="is-left"
        >
            <b-breadcrumb-item tag='router-link' to="/">Inicio</b-breadcrumb-item>
            <b-breadcrumb-item tag='router-link' to="/cientes">Trabajadores</b-breadcrumb-item>
            <b-breadcrumb-item active>Editar trabajador</b-breadcrumb-item>
        </b-breadcrumb>
        <form-trabajador :trabajador="datosTrabajador" @registrar="onEditar" v-if="datosTrabajador" />
        <b-loading :is-full-page="true" v-model="cargando" :can-cancel="false"></b-loading>
    </section>
</template>
<script>
    import HttpService from '../../Servicios/HttpService'
    import FormTrabajador from './FormTrabajador'

    export default{
        name: "EditaTrabajador",
        components: { FormTrabajador },

        data:()=>({
            cargando: false,
            datosTrabajador: null
        }),

        async mounted(){
            this.cargando = true    
            const trabajador = await HttpService.obtenerConConsultas('trabajadores.php', {
                accion: 'obtener_por_id',
                id: this.$route.params.id
            })

            this.datosTrabajador = trabajador
            this.cargando = false
        },

        methods: {
            async onEditar(datosTrabajador){
                this.cargando = true
                const resultado = await HttpService.editar('trabajadores.php',{
                    accion: 'editar',
                    trabajador: datosTrabajador
                })

                if(resultado) {
                    this.cargando = false
                    this.$buefy.toast.open({
                         type: 'is-info',
                         message: 'Información de trabajador actualizada con éxito.'
                    })
                    this.$router.push({ name: 'TrabajadoresComponent'})
                }
            }
        }
    }
</script>