<template>
	<b-table
		:data="listaProductos">
			<b-table-column field="codigo" label="Código Barra" v-slot="props">
				{{ props.row.codigobarra }}
			</b-table-column>

			<b-table-column field="nombre" label="Nombre" v-slot="props">
				{{ props.row.nombremarca }}
			</b-table-column>

			<b-table-column field="preciocompra" label="P. Compra" v-slot="props">
				{{ props.row.precioCompra }}
			</b-table-column>

			<b-table-column field="precio" label="Precio" v-slot="props">
				<b-field>
					<b-input @input="actualizarTotal()" v-model="props.row.precio" class="is-width-13-e"></b-input>
				</b-field>
			</b-table-column>

			<b-table-column field="existencia" label="Existencia" v-slot="props">
				<b-field>
					{{  props.row.existencia - props.row.cantidad }}
				</b-field>
			</b-table-column>

			<b-table-column field="cantidad" label="Cantidad" v-slot="props">
				<b-field>
					<b-numberinput @input="aumentar(props.row)" min="1" :max="props.row.existencia" v-model="props.row.cantidad" class="is-width-13-e"></b-numberinput>
				</b-field>
			</b-table-column>

			<b-table-column field="subtotal" label="Subtotal" v-slot="props">
				<b>S/. {{ props.row.precio * props.row.cantidad }}</b>
			</b-table-column>

			<b-table-column field="descuento" label="Descuento" v-slot="props">
				<b-field>
					<b-input @input="actualizarTotal()" v-model="props.row.descuento" class="is-width-13-e"></b-input>
				</b-field>
			</b-table-column>

			<b-table-column field="quitar" label="Quitar" v-slot="props">
				<b-button icon-left="delete" type="is-danger" @click="quitar(props.row.id)">
					Quitar
				</b-button>
			</b-table-column>


		</b-table>
</template>
<script>
	export default {
		name: "TablaProductos",
		props: ["listaProductos"],

		data:()=>({

		}),

		methods: {
			quitar(id){
				this.$emit("quitar", id)
			},

			aumentar(producto){
				this.$emit("aumentar", producto)
			},

			actualizarTotal() {
				this.$emit("actualizarTotal")
			},
		}
	}
</script>