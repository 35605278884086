<template>
	<section>
		<nav-component :titulo="'Clientes'" :link="{ path: '/agregar-cliente' }" :texto="'Agregar cliente'" />

		<div class="is-align-end">
            <b-button type="is-success" icon-left="microsoft-excel" @click="exportar(clientes)">Exportar</b-button>
        </div>

		<b-select v-model="perPage">
            <option value="5">5 por página</option>
            <option value="10">10 por página</option>
            <option value="15">15 por página</option>
            <option value="20">20 por página</option>
        </b-select>
		<b-table
		:data="clientes"
		:paginated="isPaginated"
        :per-page="perPage"
		:current-page.sync="currentPage"
		>
			<b-table-column field="paterno" label="Ape. Paterno" sortable searchable v-slot="props">
				{{ props.row.apellidoPaterno }}
			</b-table-column>

			<b-table-column field="materno" label="Ape. Materno" sortable searchable v-slot="props">
				{{ props.row.apellidoMaterno }}
			</b-table-column>

			<b-table-column field="nombres" label="Nombres" sortable searchable v-slot="props">
				{{ props.row.nombres }}
			</b-table-column>

			<b-table-column field="telefono" label="Teléfono" sortable searchable v-slot="props">
				{{ props.row.telefono }}
			</b-table-column>

			<b-table-column field="eliminar" label="Eliminar" v-slot="props">
				<b-button type="is-danger" icon-left="delete" @click="eliminar(props.row.id)">Eliminar</b-button>
			</b-table-column>

			<b-table-column field="editar" label="Editar" v-slot="props">
				<b-button type="is-info" icon-left="pen" @click="editar(props.row.id)">Editar</b-button>
			</b-table-column>
		</b-table>
		<b-loading :is-full-page="true" v-model="cargando" :can-cancel="false"></b-loading>
	</section>
</template>
<script>
	import NavComponent from '../Extras/NavComponent'
	import HttpService from '../../Servicios/HttpService'
	import Utiles from '../../Servicios/Utiles'
	import * as XLSX from 'xlsx'

	export default{
		name: "ClientesComponent",
		components: { NavComponent },

		data:()=>({
			cargando: false,
			clientes: [],
			isPaginated: true,
			currentPage: 1,
            perPage: 5,
		}),

		mounted(){
			this.obtenerClientes()
		},

		methods:{
			exportar(data) {
                if (data.length != 0) {
                    const excelData = data.map(row => {
                    return {
                        apellidoPaterno: row.apellidoPaterno,
						apellidoMaterno: row.apellidoMaterno,
						nombres: row.nombres,
						telefono: row.telefono
                        }  
                    })
                    const worksheet = XLSX.utils.json_to_sheet(excelData)
                    const workbook = XLSX.utils.book_new()
                    XLSX.utils.book_append_sheet(workbook, worksheet, 'Clientes')

                    // Exportar a Excel 
                    XLSX.writeFile(workbook, Utiles.generarfechahoraexportarexcel("Clientes"))
                } else {
                    alert("no hay nada que exportar");
                }
            },
			async eliminar(idCliente){
                this.$buefy.dialog.confirm({
                    title: 'Eliminar cliente',
                    message: 'Seguro que quieres <b>eliminar</b> este cliente? Esta acción no se puede revertir.',
                    confirmText: 'Sí, eliminar',
                    cancelText: 'Cancelar',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {
                        this.cargando = true
                        HttpService.eliminar('clientes.php',{
                            accion: 'eliminar',
                            id: idCliente
                        })
                        .then(resultado => {
                            if(!resultado) {
                                this.$buefy.toast.open('Error al eliminar')
                                this.cargando = false
                                return
                            }

                            if(resultado){
                                this.cargando = false
                                this.$buefy.toast.open({
                                    type: 'is-info',
                                    message: 'Cliente eliminado.'
                                })
                                this.obtenerClientes()
                            }
                        })
                    }
                })
            },

			editar(idCliente){
				this.$router.push({
					name: "EditarCliente",
					params: { id: idCliente}
				})
			},

			obtenerClientes(){
				this.cargando = true
				let payload = {
					accion: "obtener",
				}

				HttpService.obtenerConConsultas("clientes.php", payload)
				.then(clientes =>{
					this.clientes = clientes
					this.cargando = false
				})
			}
		}
	}
</script>