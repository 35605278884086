<template>
	<section class="box">
        <b-field grouped group-multiline>
            <b-field label="Apellido Paterno" expanded >
                <b-input step="any" icon="account" type="text" placeholder="Ej. Don Paco" v-model="datosTrabajador.apellidoPaterno"></b-input>
            </b-field>
            <b-field label="Apellido Materno" expanded>
                <b-input step="any" icon="account" type="text" placeholder="Ej. Don Paco" v-model="datosTrabajador.apellidoMaterno"></b-input>
            </b-field>
            <b-field label="Nombre del trabajador" expanded>
                <b-input step="any" icon="account" type="text" placeholder="Ej. Don Paco" v-model="datosTrabajador.nombre"></b-input>
            </b-field>
        </b-field>
        <br />
        <b-field grouped group-multiline>
            <b-field label="Fecha de Ingreso" expanded >
                <b-input step="any" icon="phone" type="date" placeholder="Ej. 2311459874" v-model="datosTrabajador.fechaIngreso"></b-input>
            </b-field>
            <b-field label="Fecha de Salida" expanded>
                <b-input step="any" icon="phone" type="date" placeholder="Ej. 2311459874" v-model="datosTrabajador.fechaSalida"></b-input>
            </b-field>
        </b-field>
        <br />
         <div class="buttons has-text-centered">
            <b-button type="is-primary" size="is-large" icon-left="check" @click="registrar">Registrar</b-button>
            <b-button type="is-dark" size="is-large" icon-left="cancel" tag="router-link" to="/trabajadors">Cancelar</b-button>
        </div>
        <errores-component :errores="mensajesError" v-if="mensajesError.length > 0" />
    </section>
</template>
<script>
	import Utiles from '../../Servicios/Utiles'
	import ErroresComponent from '../Extras/ErroresComponent'
	export default {
		name: "FormTrabajador",
		props: ["trabajador"],
		components: { ErroresComponent },

		data:()=>({
			datosTrabajador: {
				nombre: "",
				apellidoPaterno: "",
                apellidoMaterno: "",
                fechaIngreso: "",
                fechaSalida: "",
			},
			mensajesError: []
		}),

		mounted(){
			this.datosTrabajador = this.trabajador
		},

		methods: {
			registrar(){
				this.mensajesError = Utiles.validarDatos(this.datosTrabajador)
				if(this.mensajesError.length > 0) return
				this.$emit("registrar", this.datosTrabajador)
				this.datosTrabajador  = {
					nombre: "",
					apellidoPaterno: "",
                    apellidoMaterno: "",
                    fechaIngreso: "",
                    fechaSalida: "",
				}
			}
		}
	}
</script>