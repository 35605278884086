<template>
    <section>
        <div class="comprobante" id="comprobante" v-if="datosNegocio">
            <p class="is-title"><b>{{ titulo }}</b></p>
            <p class="is-title-company-name"><b>{{ datosNegocio.nombre }}</b></p>
            <p class="is-title-address">{{ datosNegocio.direccion }}</p>
            <p class="is-title-telephone">CELULAR: {{ datosNegocio.telefono }}</p>
            <p class="is-lines">........................................................................</p>
            <table width="100%" class="is-table-info-company">
                <tr>
                    <td><b>CLIENTE:</b></td>
                    <td align="right">{{ venta.nombreCompleto }}</td>
                </tr>
                <tr>
                    <td><b>USUARIO:</b></td>
                    <td align="right">{{ venta.nombreUsuario }}</td>
                </tr>
                <tr>
                    <td><b>FECHA:</b></td>
                    <td align="right">{{ venta.fecha }}</td>
                </tr>
                <tr v-if="venta.nombreTrabajador">
                    <td><b>TRABAJADOR:</b></td>
                    <td align="right">{{ venta.nombreTrabajador }}</td>
                </tr>
            </table>
            <p class="is-lines">........................................................................</p>
            <table width="100%" class="is-table-info-sales">
                <thead>
                    <tr>
                        <th>PRODUCTO</th>
                        <th align="right">TOTAL</th>
                    </tr>
                    <tr>
                        <th colspan="2">.........................................</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(producto, index) in venta.productos" :key="index">
                        <td>
                            {{ producto.nombre }} <span v-if="producto.marca">({{ producto.marca }})</span>
                            <br>
                            {{ producto.cantidad }} x {{ producto.precio}}
                        </td>
                        <td align="right">{{ formatearMoneda(producto.precio * producto.cantidad) }}</td>
                    </tr>
                </tbody>
            </table>
            <p class="is-lines">..............................................</p>
            <table width="100%" class="is-table-info-total">
                <tr>
                    <td><b>SUB-TOTAL:</b></td>
                    <td align="right"> {{ formatearMoneda(venta.totalsindescuento)  }} </td>
                </tr>
                <tr>
                    <td><b>DESCUENTO:</b></td>
                    <td align="right">{{ formatearMoneda(venta.totalsindescuento - venta.total) }}</td>
                </tr>
                <tr>
                    <td><b>TOTAL:</b></td>
                    <td align="right">{{ formatearMoneda(venta.total) }}</td>
                </tr>
            </table>
            <p class="is-lines">.......................................................</p>
            <p class="is-title-info-voucher">Recuerde canjear, por su boleta o factura electrónica.</p>
            <p class="is-info-thankyou"><b>¡Gracias por su preferencia¡</b></p>
        </div>
    </section>
</template>
<script>
    import AyudanteSesion from '../../Servicios/AyudanteSesion'
    import Utiles from '../../Servicios/Utiles'
    import Printd from "printd";

    export default{
        name: "ComprobanteCompra",
        props: ["venta", "tipo"],

        data:()=>({
            titulo: "",
            datosNegocio: null,
            cssText: `
                *,*::after,*::before {
                    margin: 0;
                    padding: 0;
                }

                table {
                    margin-left: auto;
                    margin-right: auto;
                    border-collapse: collapse;
                }

                table tr td {
                    padding: .045rem !important;
                }

                .comprobante {
                    
                    font-family: monospace;
                    text-align: left;
                }

                .is-title-company-name {
                    font-size: 14px;
                }

                .comprobante > p {
                    margin: 0 !important;
                    padding: 0 !important;
                    text-align: center;
                }

                .comprobante > img {
                    display: block;
                    margin: 0 auto;
                }

                .comprobante > th, td {
                    margin: 0 !important;
                    padding: 0 !important;
                }

                .comprobante .is-title,
                .comprobante .is-title-company-name,
                .comprobante .is-title-address,
                .comprobante .is-title-telephone,
                .comprobante .is-lines {
                    margin-bottom: 5px !important;
                }
            `,

        }),

        beforeMount(){
            this.generarTitulo()
            this.obtenerDatosNegocio()
        },

        mounted(){
            this.d = new Printd();
            const { contentWindow } = this.d.getIFrame();
            contentWindow.addEventListener("beforeprint", () =>
              console.log("before print event!")
            );
            contentWindow.addEventListener("afterprint", () =>
              console.log("after print event!")
            );

            this.imprimir();
        },

        methods:{
            formatearMoneda(valor) {
                valor = parseFloat(valor)
                return valor.toFixed(2)
            },
            generarTitulo(){
                switch(this.tipo){
                    case "venta":
                        this.titulo = "COMPROBANTE DE COMPRA"
                        break
                    case "cuenta":
                        this.titulo = "COMPROBANTE DE CUENTA"
                        break

                    case "apartado":
                        this.titulo = "COMPROBANTE DE APARTADO"
                        break

                    case "cotiza":
                        this.titulo = "COTIZACIÓN"
                        break

                    default:
                        this.titulo = "COMPROBANTE"
                }
            },

            obtenerDatosNegocio(){
                this.datosNegocio = AyudanteSesion.obtenerDatosNegocio()
                this.datosNegocio.logo = Utiles.regresarRuta() + this.datosNegocio.logo
            },

            imprimir() {
              let zona = document.getElementById("comprobante");
              setTimeout(() => this.d.print(zona, [this.cssText]), 10, {scaleContent: 0.6 });
              this.$emit("impreso", false);
            },
        }
    }
</script>